// localhost - 0x4AAAAAAAkndjda8IgY1TzW
// master, dev - 0x4AAAAAAAknGFgpe9666LCr
// prod - 0x4AAAAAAAvY_nPd5cUeavE8
import React, { useEffect, useRef } from 'react';

type TProps = {
  onVerify: (token: string) => void;
};

const InvisibleCaptcha: React.FC<TProps> = ({ onVerify }) => {
  const turnstileRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const createWidget = () => {
      if (turnstileRef.current && (window as any).turnstile) {
        (window as any).turnstile.render(turnstileRef.current, {
          sitekey: '0x4AAAAAAAknGFgpe9666LCr', // Замените на ваш реальный ключ сайта
          callback: (token: string) => {
            // console.log('Token received:', token);
            if ((window as any).ReactNativeWebView) {
              (window as any).ReactNativeWebView.postMessage(token);
            }
            onVerify(token);
          },
          'error-callback': () => console.error('Turnstile error'),
          'expired-callback': () => console.warn('Turnstile token expired'),
          size: 'invisible', // Невидимый режим
        });

        // Автоматическое исполнение после создания
        (window as any).turnstile.execute();
      }
    };

    // Проверяем наличие скрипта Turnstile
    if (!(window as any).turnstile) {
      const script = document.createElement('script');
      script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
      script.async = true;
      script.onload = createWidget; // Запуск создания виджета после загрузки
      document.head.appendChild(script);
    } else {
      createWidget(); // Если скрипт уже загружен, создаем виджет сразу
    }

    return () => {
      if (turnstileRef.current && (window as any).turnstile) {
        (window as any).turnstile.remove(turnstileRef.current); // Чистим ресурсы, когда компонент размонтируется
      }
    };
  }, [onVerify]);

  return <div ref={turnstileRef} />;
};

export default InvisibleCaptcha;
